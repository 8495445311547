<template>
  <bg-modal
    :value="isOpen"
    title="Yakin approve pendapatan pemilik dan kirim laporan?"
    description="Sistem akan otomatis mengirim laporan pendapatan ke pemilik. Data yang sudah di-approve tidak dapat dibatalkan."
    desktop-size="sm"
    :closable="false"
    :close-on-click-backdrop="false"
    @close="closeModal"
  >
    <template #footer>
      <div class="flex justify-end">
        <bg-button @click="closeModal">Batal</bg-button>
        <bg-button
          :loading="isLoading"
          variant="primary"
          class="ml-16"
          @click="approveAndSendReport"
          >Approve & Kirim Laporan</bg-button
        >
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import detailTransferApi from '@admin_endpoints/detail-transfer';

export default {
  name: 'ApproveAndSendReportModal',

  components: {
    BgModal,
    BgButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedApprovalId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    approvalId() {
      return this.selectedApprovalId || this.$route.params.approval_id;
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:selectedApprovalId', null);
    },

    async approveAndSendReport() {
      this.isLoading = true;
      try {
        await detailTransferApi.pushNotification(this.approvalId);
        this.$toast.show('Laporan pendapatan berhasil dikirim.');
        this.$emit('action-success');
        this.closeModal();
      } catch (error) {
        let errorMessage = 'Terjadi galat, silahkan coba beberapa saat lagi.';
        console.error(error);
        if (error?.response?.status === 404) {
          const errorDetails = Object.values(
            error.response.data.issue?.details || {}
          );
          errorMessage = errorDetails[0] || errorMessage;
        }
        this.$toast.show(errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
